import { FunctionComponent } from "react";

import AccordionList from "components/AccordionList";
import AccordionListProps from "components/AccordionList/AccordionListProps";
import ActionEntryPointList from "components/ActionEntryPointList";
import ActionEntryPointListProps from "components/ActionEntryPointList/ActionEntryPointListProps";
import BlockQuote from "components/BlockQuote";
import BlockQuoteProps from "components/BlockQuote/BlockQuoteProps";
import BlockQuoteList from "components/BlockQuoteList";
import BlockQuoteListProps from "components/BlockQuoteList/BlockQuoteListProps";
import BlockQuoteSection from "components/BlockQuoteSection";
import BlockQuoteSectionProps from "components/BlockQuoteSection/BlockQuoteSectionProps";
import BusinessAreaLogo from "components/BusinessAreaLogo";
import BusinessAreaLogoProps from "components/BusinessAreaLogo/BusinessAreaLogoProps";
import Button from "components/Button";
import ButtonProps, { SubmitButtonProps } from "components/Button/ButtonProps";
import SubmitButton from "components/Button/SubmitButton";
import CallToActionSlimSection from "components/CallToActionSlimSection";
import CallToActionSlimSectionProps from "components/CallToActionSlimSection/CallToActionSlimSectionProps";
import ChoiceElement from "components/ChoiceElement";
import ChoiceElementProps from "components/ChoiceElement/ChoiceElementProps";
import Disclaimer from "components/Disclaimer";
import DisclaimerProps from "components/Disclaimer/DisclaimerProps";
import Document from "components/Document";
import DocumentProps from "components/Document/DocumentProps";
import EditorialSection from "components/EditorialSection";
import EditorialSectionProps from "components/EditorialSection/EditorialSectionProps";
import Embed from "components/Embed";
import EmbedProps from "components/Embed/EmbedProps";
import FactBox from "components/FactBox";
import FactBoxProps from "components/FactBox/FactBoxProps";
import FeaturedNews from "components/FeaturedNews";
import FeaturedNewsProps from "components/FeaturedNews/FeaturedNewsProps";
import FileUpload from "components/FileUpload";
import FileUploadProps from "components/FileUpload/FileUploadProps";
import FormContent from "components/FormContent";
import FormContentProps from "components/FormContent/FormContentProps";
import FormInfoBox from "components/FormInfo";
import FormInfoBoxProps from "components/FormInfo/FormInfoBoxProps";
import FormParagraphText from "components/FormParagraphText";
import FormParagraphTextProps from "components/FormParagraphText/FormParagraphTextProps";
import FormResultItem from "components/FormResultItem";
import FormResultItemProps from "components/FormResultItem/FormResultItemProps";
import FormSection from "components/FormSection";
import FormSectionProps from "components/FormSection/FormSectionProps";
import FormWithImage from "components/FormWithImage/FormWithImage";
import FormWithImageProps from "components/FormWithImage/FormWithImageProps";
import FrequentlyAskedQuestionListSection from "components/FrequentlyAskedQuestionListSection/FrequentlyAskedQuestionListSection";
import FrequentlyAskedQuestionListSectionProps from "components/FrequentlyAskedQuestionListSection/FrequentlyAskedQuestionListSectionProps";
import HiddenField from "components/HiddenField";
import HiddenFieldProps from "components/HiddenField/HiddenFieldProps";
import ImageSection from "components/ImageSection";
import ImageSectionProps from "components/ImageSection/ImageSectionProps";
import KeyFiguresList from "components/KeyFiguresList";
import KeyFiguresListProps from "components/KeyFiguresList/KeyFiguresListProps";
import MapSection from "components/MapSection";
import MapSectionProps from "components/MapSection/MapSectionProps";
import NewsletterSignup from "components/NewsletterSignup";
import NewsletterSignupProps from "components/NewsletterSignup/NewsletterSignupProps";
import NewsPageSection from "components/NewsPageSection";
import NewsPageSectionProps from "components/NewsPageSection/NewsPageSectionProps";
import OpenPositionListSection from "components/OpenPositionListSection";
import OpenPositionListSectionProps from "components/OpenPositionListSection/OpenPositionListSectionProps";
import PageGrid from "components/PageGrid";
import PageGridProps from "components/PageGrid/PageGridProps";
import PageList from "components/PageList";
import PageListProps from "components/PageList/PageListProps";
import Process from "components/Process";
import ProcessProps from "components/Process/ProcessProps";
import ProductCategoryList from "components/ProductCategoryList";
import ProductCategoryListProps from "components/ProductCategoryList/ProductCategoryListProps";
import ProfileList from "components/ProfileList";
import ProfileListProps from "components/ProfileList/ProfileListProps";
import ProfileListSection from "components/ProfileListSection";
import ProfileListSectionProps from "components/ProfileListSection/ProfileListSectionProps";
import Promotion from "components/Promotion";
import PromotionProps from "components/Promotion/PromotionProps";
import RelatedContent from "components/RelatedContent";
import RelatedContentProps from "components/RelatedContent/RelatedContentProps";
import SalaryExchange from "components/SalaryExchange";
import SalaryExchangeProps from "components/SalaryExchange/SalaryExchangeProps";
import Select from "components/Select";
import SelectProps from "components/Select/SelectProps";
import SideBySide from "components/SideBySide";
import SideBySideProps from "components/SideBySide/SideBySideProps";
import SideBySideFullBleed from "components/SideBySideFullBleed";
import SideBySideFullBleedProps from "components/SideBySideFullBleed/SideBySideFullBleedProps";
import Slider from "components/Slider";
import SliderProps from "components/Slider/SliderProps";
import TextArea from "components/TextArea";
import TextAreaProps from "components/TextArea/TextAreaProps";
import TextBlock from "components/TextBlock";
import TextBlockProps from "components/TextBlock/TextBlockProps";
import TextField from "components/TextField";
import TextFieldProps from "components/TextField/TextFieldProps";
import UniqueSellingPointList from "components/UniqueSellingPointList";
import UniqueSellingPointListProps from "components/UniqueSellingPointList/UniqueSellingPointListProps";
import Video from "components/Video";
import VideoProps from "components/Video/VideoProps";
import VideoList from "components/VideoList";
import VideoListProps from "components/VideoList/VideoListProps";
import ArticlePage from "pages/ArticlePage";
import ContactPage from "pages/ContactPage";
import FormGuidePage from "pages/FormGuidePage";
import MortgageApplicationPage from "pages/MortgageApplicationPage";
import NewsListingPage from "pages/NewsListingPage";
import NewsPage from "pages/NewsPage";
import NotFoundPage from "pages/NotFoundPage";
import OfficeListingPage from "pages/OfficeListingPage";
import OfficePage from "pages/OfficePage";
import OpenPositionsListingPage from "pages/OpenPositionsListingPage";
import ProductCategoryPage from "pages/ProductCategoryPage";
import ProductPage from "pages/ProductPage";
import ProfilePage from "pages/ProfilePage";
import ReferencePage from "pages/ReferencePage";
import SearchResultsPage from "pages/SearchResultsPage";
import StartPage from "pages/StartPage";
import SupportPage from "pages/SupportPage";
import WhiteLabelPage from "pages/WhiteLabelPage";
import EpiContent from "types/EpiContent";
import EpiPage from "types/EpiPage";

const isServer = typeof window === "undefined";

const _clientOnly = (
    component: FunctionComponent<EpiContent>,
): FunctionComponent<EpiContent> | undefined => {
    return !isServer ? component : undefined;
};

export const components: Record<
    string,
    FunctionComponent<EpiContent> | undefined
> = {
    // Pages
    NotFoundPage: NotFoundPage as FunctionComponent<
        EpiPage | Record<string, unknown>
    >,
    ArticlePage: ArticlePage as FunctionComponent<EpiPage>,
    FormGuidePage: FormGuidePage as FunctionComponent<EpiPage>,
    NewsListingPage: NewsListingPage as FunctionComponent<EpiPage>,
    NewsPage: NewsPage as FunctionComponent<EpiPage>,
    ProductCategoryPage: ProductCategoryPage as FunctionComponent<EpiPage>,
    ProductPage: ProductPage as FunctionComponent<EpiPage>,
    StartPage: StartPage as FunctionComponent<EpiPage>,
    WhiteLabelPage: WhiteLabelPage as FunctionComponent<EpiPage>,
    MortgageApplicationPage:
        MortgageApplicationPage as FunctionComponent<EpiPage>,
    ContactPage: ContactPage as FunctionComponent<EpiPage>,
    SupportPage: SupportPage as FunctionComponent<EpiPage>,
    ProfilePage: ProfilePage as FunctionComponent<EpiPage>,
    ReferencePage: ReferencePage as FunctionComponent<EpiPage>,
    OpenPositionsListingPage:
        OpenPositionsListingPage as FunctionComponent<EpiPage>,
    OfficePage: OfficePage as FunctionComponent<EpiPage>,
    OfficeListingPage: OfficeListingPage as FunctionComponent<EpiPage>,
    SearchResultsPage: SearchResultsPage as FunctionComponent<EpiPage>,
    PageNotFound: NotFoundPage as FunctionComponent<EpiPage>,

    // Blocks
    AccordionListBlock: AccordionList as FunctionComponent<AccordionListProps>,
    QuoteListBlock: BlockQuoteList as FunctionComponent<BlockQuoteListProps>,
    QuoteBlock: BlockQuote as FunctionComponent<BlockQuoteProps>,
    QuoteSectionBlock:
        BlockQuoteSection as FunctionComponent<BlockQuoteSectionProps>,
    QuoteSectionWithImageBlock:
        BlockQuoteSection as FunctionComponent<BlockQuoteSectionProps>,
    CallToActionSlimBlock:
        CallToActionSlimSection as FunctionComponent<CallToActionSlimSectionProps>,
    TextBlock: TextBlock as FunctionComponent<TextBlockProps>,
    EditorialSectionBlock:
        EditorialSection as FunctionComponent<EditorialSectionProps>,
    FactBoxBlock: FactBox as FunctionComponent<FactBoxProps>,
    Image: ImageSection as FunctionComponent<ImageSectionProps>,
    Document: Document as FunctionComponent<DocumentProps>,
    VideoBlock: Video as FunctionComponent<VideoProps>,
    LinkBlock: Button as FunctionComponent<ButtonProps>,
    OpenPositionsBlock:
        OpenPositionListSection as FunctionComponent<OpenPositionListSectionProps>,
    ProfileListBlock: ProfileList as FunctionComponent<ProfileListProps>,
    TwoColumnProfileListBlock:
        ProfileListSection as FunctionComponent<ProfileListSectionProps>,
    PromotionBlock: Promotion as FunctionComponent<PromotionProps>,
    PromotionWithImageBlock: Promotion as FunctionComponent<PromotionProps>,
    BusinessAreaLogoBlock:
        BusinessAreaLogo as FunctionComponent<BusinessAreaLogoProps>,
    ActionEntryPointsBlock:
        ActionEntryPointList as FunctionComponent<ActionEntryPointListProps>,
    FeaturedNewsBlock: FeaturedNews as FunctionComponent<FeaturedNewsProps>,
    PageListBlock: PageList as FunctionComponent<PageListProps>,
    PageGridBlock: PageGrid as FunctionComponent<PageGridProps>,
    FormContainerBlock: FormSection as FunctionComponent<FormSectionProps>,
    UniqueSellingPointListBlock:
        UniqueSellingPointList as FunctionComponent<UniqueSellingPointListProps>,
    ProductCategoryListBlock:
        ProductCategoryList as FunctionComponent<ProductCategoryListProps>,
    NewsletterSignupBlock:
        NewsletterSignup as FunctionComponent<NewsletterSignupProps>,
    DisclaimerBlock: Disclaimer as FunctionComponent<DisclaimerProps>,
    NewsPageSectionBlock:
        NewsPageSection as FunctionComponent<NewsPageSectionProps>,
    FrequentlyAskedQuestionsBlock:
        FrequentlyAskedQuestionListSection as FunctionComponent<FrequentlyAskedQuestionListSectionProps>,
    FrequentlyAskedQuestionsByCategoryBlock:
        FrequentlyAskedQuestionListSection as FunctionComponent<FrequentlyAskedQuestionListSectionProps>,
    RelatedContentBlock:
        RelatedContent as FunctionComponent<RelatedContentProps>,
    EmbedBlock: Embed as FunctionComponent<EmbedProps>,
    MapBlock: MapSection as FunctionComponent<MapSectionProps>,
    KeyFigureListBlock:
        KeyFiguresList as FunctionComponent<KeyFiguresListProps>,
    VideoListBlock: VideoList as FunctionComponent<VideoListProps>,
    ProcessBlock: Process as FunctionComponent<ProcessProps>,
    SideBySideBlock: SideBySide as FunctionComponent<SideBySideProps>,
    FormInfoBoxBlock: FormInfoBox as FunctionComponent<FormInfoBoxProps>,
    FormResultItemBlock:
        FormResultItem as FunctionComponent<FormResultItemProps>,
    SideBySideFullBleedBlock:
        SideBySideFullBleed as FunctionComponent<SideBySideFullBleedProps>,
    SalaryExchangeBlock:
        SalaryExchange as FunctionComponent<SalaryExchangeProps>,
    FormWithImageBlock: FormWithImage as FunctionComponent<FormWithImageProps>,

    // Form Elements
    ChoiceElementBlock: ChoiceElement as FunctionComponent<ChoiceElementProps>,
    RangeElementBlock: Slider as FunctionComponent<SliderProps>,
    SelectionElementBlock: Select as FunctionComponent<SelectProps>,
    TextboxElementBlock: TextField as FunctionComponent<TextFieldProps>,
    ExtendedInputElementBlock: TextField as FunctionComponent<TextFieldProps>,
    TextareaElementBlock: TextArea as FunctionComponent<TextAreaProps>,
    NumberElementBlock: TextField as FunctionComponent<TextFieldProps>,
    SubmitButtonElementBlock:
        SubmitButton as FunctionComponent<SubmitButtonProps>,
    PredefinedHiddenElementBlock:
        HiddenField as FunctionComponent<HiddenFieldProps>,
    VisitorDataHiddenElementBlock:
        HiddenField as FunctionComponent<HiddenFieldProps>,
    ParagraphTextElementBlock:
        FormParagraphText as FunctionComponent<FormParagraphTextProps>,
    FileUploadElementBlock: FileUpload as FunctionComponent<FileUploadProps>,
    FormContentBlock: FormContent as FunctionComponent<FormContentProps>,
};

const getComponentTypeForContent = ({
    component,
}: EpiContent): FunctionComponent<EpiContent> | undefined => {
    if (component && components[component]) {
        return components[component];
    } else {
        return undefined;
    }
};

export default getComponentTypeForContent;
