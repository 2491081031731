import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FormContentProps from "./FormContentProps";

const FormContent = loadable(
    (): Promise<DefaultComponent<FormContentProps>> =>
        import(/* webpackChunkName: "FormContent" */ "./FormContent"),
);
const FormContentLoader = (props: FormContentProps): ReactElement => (
    <FormContent {...props} />
);
export default FormContentLoader;
