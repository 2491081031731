import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FormInfoBoxProps from "./FormInfoBoxProps";

const FormInfoBox = loadable(
    (): Promise<DefaultComponent<FormInfoBoxProps>> =>
        import(/* webpackChunkName: "FormInfoBox" */ "./FormInfoBox"),
);
const FormInfoBoxLoader = (props: FormInfoBoxProps): ReactElement => (
    <FormInfoBox {...props} />
);
export default FormInfoBoxLoader;
