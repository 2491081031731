import { styled, css } from "styled-components";
import theme from "styled-theming";

import Form from "components/Form";
import TextBlock from "components/TextBlock";
import { Container as GridContainer } from "style/components/Page";
import { styleHeadingXL, styleBodyL } from "style/components/Typography";
import { columnGap, gap } from "style/grid";
import { MQ } from "style/mediaQueries";

export const Container = styled(GridContainer)`
    ${gap}
    align-items: center;
    display: grid;
    grid-template-columns: repeat(1, [col-start] 1fr);

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
    }

    ${MQ.FROM_L} {
        grid-auto-rows: auto;
        grid-template-columns: repeat(12, [col-start] 1fr);
    }
`;

export const Heading = styled.h2`
    ${styleHeadingXL};
    grid-column: col-start 1 / span 1;
    margin-top: 0;

    ${theme("displayOption", {
        Right: css`
            text-align: right;
        `,
    })};

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 7;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 6;
    }
`;

export const Introduction = styled.div`
    ${styleBodyL};
    grid-column: col-start 1 / span 1;
    margin-bottom: 2.1875rem;
    margin-top: 0;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 7;
    }

    ${MQ.FROM_L} {
        ${theme("displayOption", {
            Left: css`
                grid-column: col-start 2 / span 5;
            `,
            Right: css`
                grid-column: col-start 1 / span 5;
            `,
        })};
    }
`;

export const Description = styled.div`
    grid-column: col-start 1 / span 1;
    margin-bottom: 2.1875rem;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 6;
    }

    ${MQ.FROM_L} {
        ${theme("displayOption", {
            Left: css`
                grid-column: col-start 2 / span 4;
            `,
            Right: css`
                grid-column: col-start 1 / span 4;
            `,
        })};
    }
`;

export const StyledTextBlock = styled(TextBlock)`
    div {
        &:first-child :first-child {
            margin-top: 0;
        }

        &:last-child :last-child {
            margin-bottom: 0;
        }
    }
`;

export const FormWrapper = styled.div`
    grid-column: col-start 1 / span 1;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 7;
    }

    ${MQ.FROM_L} {
        ${theme("displayOption", {
            Left: css`
                grid-column: col-start 2 / span 5;
            `,
            Right: css`
                grid-column: col-start 1 / span 5;
            `,
        })};
    }
`;

export const StyledForm = styled(Form)`
    margin-top: 0 !important;
    padding-bottom: 0 !important;
`;

export const LeftCol = styled.div`
    grid-column: col-start 1 / span 1;

    ${theme("displayOption", {
        Left: css`
            ${columnGap}
            display: grid;
            grid-template-columns: repeat(1, [col-start] 1fr);
        `,
        Right: css`
            display: block;
        `,
    })};

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 1 / span 7",
            Right: "col-start 1 / span 5",
        })};
        grid-template-columns: ${theme("displayOption", {
            Left: "repeat(7, [col-start] 1fr)",
        })};
    }
`;

export const RightCol = styled.div`
    grid-column: col-start 1 / span 1;

    ${theme("displayOption", {
        Left: css`
            display: block;
            order: -1;
        `,
        Right: css`
            ${columnGap}
            display: grid;
            grid-template-columns: repeat(1, [col-start] 1fr);
        `,
    })};

    ${MQ.FROM_M} {
        grid-column: ${theme("displayOption", {
            Left: "col-start 8 / span 5",
            Right: "col-start 7 / span 6",
        })};
        grid-template-columns: ${theme("displayOption", {
            Right: "repeat(6, [col-start] 1fr)",
        })};
        order: 0;
    }
`;
