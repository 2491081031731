import { styled, css } from "styled-components";
import theme from "styled-theming";

import { FONT } from "style/fonts";
import { MQ } from "style/mediaQueries";

export const themeColor = theme("contentTheme", {
    undefined: "inherit",
    white: "var(--text-on-image-primary)",
    black: "var(--text-on-neutral-primary)",
    cyan: "var(--text-on-blue-primary)",
    blue: "var(--text-on-cyan-primary)",
    coral: "var(--text-on-neutral-primary-alt2)",
    image: "var(--text-on-image-primary)",
});

export const base = css`
    color: ${(props) =>
        props.theme?.theme === "blue" && props.theme?.contentTheme === "coral"
            ? "var(--text-on-blue-primary-alt)"
            : themeColor};
    font-family: ${FONT.WHITNEY};
`;

export const styleNumber = css`
    word-spacing: 0.1em;
`;

export const styleHeadingXXL = css`
    ${base}
    font-size: 3.0625rem;
    font-weight: 800;
    letter-spacing: -0.01em;
    line-height: 3.0625rem;

    ${MQ.FROM_M} {
        font-size: 6.125rem;
        line-height: 6.125rem;
    }

    ${MQ.FROM_XL} {
        font-size: 8.75rem;
        line-height: 8.75rem;
    }
`;

export const styleHeadingXXLAlt = css`
    ${base}
    font-size: 3.0625rem;
    font-weight: 800;
    letter-spacing: -0.01em;
    line-height: 3.0625rem;

    ${MQ.FROM_M} {
        font-size: 5.25rem;
        line-height: 5.25rem;
    }

    ${MQ.FROM_XL} {
        font-size: 7rem;
        line-height: 7rem;
    }
`;

export const styleHeadingXL = css`
    ${base}
    font-size: 2.1875rem;
    font-weight: 800;
    letter-spacing: -0.01em;
    line-height: 2.1875rem;

    ${MQ.FROM_M} {
        font-size: 3.9375rem;
        line-height: 3.9375rem;
    }

    ${MQ.FROM_XL} {
        font-size: 5.25rem;
        line-height: 5.25rem;
    }
`;

export const styleHeadingL = css`
    ${base}
    font-size: 1.75rem;
    font-weight: 800;
    letter-spacing: -0.01em;
    line-height: 1.75rem;

    ${MQ.FROM_M} {
        font-size: 2.375rem;
        line-height: 2.625rem;
    }

    ${MQ.FROM_XL} {
        font-size: 3.25rem;
        line-height: 3.5rem;
    }
`;

export const styleHeadingLAlt = css`
    font-family: ${FONT.WHITNEY};
    font-size: 1.625rem;
    font-weight: 800;
    letter-spacing: -0.01em;
    line-height: 1.45rem;

    ${MQ.FROM_M} {
        font-size: 1.75rem;
    }

    ${MQ.FROM_XL} {
        font-size: 1.9375rem;
        line-height: 2.1875rem;
    }
`;

export const styleHeadingM = css`
    ${base}
    font-size: 1.375rem;
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1.75rem;

    ${MQ.FROM_M} {
        font-size: 1.5rem;
        line-height: 1.75rem;
    }

    ${MQ.FROM_XL} {
        font-size: 1.9375rem;
        line-height: 2.1875rem;
    }
`;

export const styleHeadingS = css`
    ${base}
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.75rem;

    ${MQ.FROM_M} {
        font-size: 1.1875rem;
    }

    ${MQ.FROM_XL} {
        font-size: 1.25rem;
    }
`;

export const styleQuoteL = css`
    ${base}
    font-size: 1.75rem;
    font-style: italic;
    font-weight: 800;
    letter-spacing: -0.01em;
    line-height: 1.75rem;

    ${MQ.FROM_M} {
        font-size: 2.375rem;
        line-height: 2.625rem;
    }

    ${MQ.FROM_XL} {
        font-size: 3.25rem;
        line-height: 3.5rem;
    }
`;

export const styleQuoteM = css`
    ${base}
    font-size: 1.375rem;
    font-style: italic;
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1.75rem;

    ${MQ.FROM_M} {
        font-size: 1.5rem;
        line-height: 1.75rem;
    }

    ${MQ.FROM_XL} {
        font-size: 1.9375rem;
        line-height: 2.1875rem;
    }
`;

export const styleBodyL = css`
    ${base}
    font-size: 1.375rem;
    font-weight: 400;
    line-height: 1.75rem;

    ${MQ.FROM_M} {
        font-size: 1.5rem;
        line-height: 1.75rem;
    }

    ${MQ.FROM_XL} {
        font-size: 1.9375rem;
        line-height: 2.1875rem;
    }
`;

export const styleBodyLAlt = css`
    ${styleBodyL}
    text-indent: 4.125rem;

    ${MQ.FROM_M} {
        text-indent: 6rem;
    }

    ${MQ.FROM_XL} {
        text-indent: 7.75rem;
    }
`;

export const styleBodyM = css`
    ${base}
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.75rem;

    ${MQ.FROM_M} {
        font-size: 1.1875rem;
    }

    ${MQ.FROM_XL} {
        font-size: 1.25rem;
    }
`;

export const styleBodyS = css`
    ${base}
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.3125rem;
`;

export const styleBodySSpaced = css`
    ${base}
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.75rem;
`;

export const styleLabelM = css`
    ${base}
    font-size: 0.8125rem;
    font-weight: 700;
    letter-spacing: 0.1em;
    line-height: 1.75rem;
    text-transform: uppercase;

    ${MQ.FROM_XL} {
        font-size: 0.875rem;
    }
`;

export const styleMapCluster = css`
    color: var(--color-blue) !important;
    font-family: ${FONT.WHITNEY} !important;
    font-size: 0.8125rem !important;
    font-weight: 700;
    letter-spacing: 0.1em;

    ${MQ.FROM_XL} {
        font-size: 0.875rem !important;
    }
`;

export const styleLabelS = css`
    ${base}
    font-size: 0.8125rem;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 1.75rem;
    text-transform: uppercase;

    ${MQ.FROM_XL} {
        font-size: 0.875rem;
    }
`;

export const styleCommentL = css`
    ${base}
    font-size: 1.375rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;

    ${MQ.FROM_M} {
        font-size: 1.5rem;
        line-height: 1.75rem;
    }

    ${MQ.FROM_XL} {
        font-size: 1.9375rem;
        line-height: 2.1875rem;
    }
`;

export const styleCommentM = css`
    ${base}
    font-size: 1.125rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;

    ${MQ.FROM_M} {
        font-size: 1.1875rem;
    }

    ${MQ.FROM_XL} {
        font-size: 1.25rem;
    }
`;

export const styleCommentS = css`
    ${base}
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.3125rem;
`;

export const styleCommentSSpaced = css`
    ${base}
    font-size: 1rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.75rem;
`;

// Components
export const HeadingXXL = styled.div`
    ${styleHeadingXXL}
`;

export const HeadingXXLAlt = styled.div`
    ${styleHeadingXXLAlt}
`;

export const HeadingXL = styled.div`
    ${styleHeadingXL}
`;

export const HeadingL = styled.div`
    ${styleHeadingL}
`;

export const HeadingM = styled.div`
    ${styleHeadingM}
`;

export const QuoteL = styled.div`
    ${styleQuoteL}
`;

export const QuoteM = styled.div`
    ${styleQuoteM}
`;

export const HeadingS = styled.div`
    ${styleHeadingS}
`;

export const BodyL = styled.div`
    ${styleBodyL}
`;

export const BodyLAlt = styled.div`
    ${styleBodyLAlt}
`;

export const BodyM = styled.div`
    ${styleBodyM}
`;

export const BodyS = styled.div`
    ${styleBodyS}
`;

export const BodySSpaced = styled.div`
    ${styleBodySSpaced}
`;

export const LabelM = styled.div`
    ${styleLabelM}
`;

export const LabelS = styled.div`
    ${styleLabelS}
`;

export const CommentL = styled.div`
    ${styleCommentL}
`;

export const CommentM = styled.div`
    ${styleCommentM}
`;

export const CommentS = styled.div`
    ${styleCommentS}
`;

export const CommentSSpaced = styled.div`
    ${styleCommentSSpaced}
`;
