/* eslint-disable jsx-a11y/anchor-is-valid */
import classNames from "classnames";
import React, { MouseEvent, ReactElement, TouchEvent, useState } from "react";
import isEqual from "react-fast-compare";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";

import { useHeader } from "hooks";
import { SET_NO_SCROLL } from "store/actionTypes";
import { addGtmClickTypeAttributes } from "utils/gtm";
import { isStandarLinkInterface } from "utils/linkItemMapper";
import transferSearchParams from "utils/transferSearchParams";
import useTimeout from "utils/useTimeout";

import { A, NavigationLink, ObfuscatedLink, ReduxLink } from "./Link.styled";
import LinkProps, { OfuscatedLinkProps } from "./LinkProps";

const Link = ({
    ariaLabel,
    ariaCurrent,
    title,
    linkRef,
    to,
    id,
    url,
    children,
    className,
    style,
    tabIndex = 0,
    noScroll = false,
    hoverSpan = false,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onTouchStart,
    onTouchEnd,
    prefetch = true,
    fullfetch = false,
    isNavigation = false,
    target = "",
    gtmClickType,
}: LinkProps): ReactElement => {
    const searchParamsToKeep = ["gtm_debug"];
    const dispatch = useDispatch();
    const classes = classNames(className, { hoverspan: hoverSpan });

    let toLink = url || to || "";

    if (isStandarLinkInterface(linkRef)) {
        toLink = linkRef.url ? linkRef.url : "";
    }

    let linkTitle = title ? title : "";
    if (isStandarLinkInterface(linkRef)) {
        linkTitle = linkRef.title ? linkRef.title : "";
    }

    // Fix strange Epi mailto links
    if (toLink.startsWith("mailto:")) {
        toLink = toLink.replace(/:25$/, "").replace("mailto://", "mailto:");
    }

    const isFile = (to: string): boolean => {
        if (!to) return false;

        const subLength = to.indexOf("?") !== -1 ? to.indexOf("?") : to.length;
        const href = to.substring(0, subLength);

        if (!href || href.length < 1) return false;
        if (href.endsWith(".pdf")) return true;
        if (href.indexOf("/") >= 0) {
            const path = href.split("/");
            if (path) {
                const last = path.pop();
                if (last) {
                    return last.indexOf(".") > -1;
                }
            }
        }

        return false;
    };

    const [doPrefetch, setDoPrefetch] = useState<boolean>(false);
    const [doDelayedPrefetch, setDoDelayedPrefetch] = useState<boolean>(false);

    useTimeout(
        () => {
            setDoPrefetch(true);
        },
        doDelayedPrefetch && typeof prefetch === "number" ? prefetch : null,
    ),
        [doDelayedPrefetch, prefetch];

    const isOnPageAnchor = toLink?.startsWith("#");

    const prefetchURL = (url: string): string =>
        url.indexOf("?") > -1 ? `${url}&asjson=true` : `${url}?asjson=true`;

    const isLocalUrl = (url: string): boolean => {
        if (
            (url.startsWith("/") && !url.startsWith("//")) ||
            url.startsWith("#")
        ) {
            return true;
        }
        if (typeof window !== "undefined" && window?.location) {
            const re = `(?:(?:http)*s*):*\\/\\/${window.location.hostname}`;
            if (url.match(new RegExp(re))) {
                return true;
            }
        }
        return false;
    };

    const onClickHandler = (event: MouseEvent): void => {
        dispatch({
            type: SET_NO_SCROLL,
            payload: noScroll,
        });
        if (onClick && !(event.ctrlKey || event.metaKey)) {
            onClick(event);
        }

        const hrefAttribute =
            (event.target as HTMLElement).getAttribute("href") ?? "";
        const targetUrl = hrefAttribute || (to ?? "");
        const currentPath = window?.location.pathname + "";

        if (window?.location && targetUrl === currentPath) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            return;
        }
    };

    const onMouseEnterHandler = (event: MouseEvent): void => {
        if (onMouseEnter) {
            onMouseEnter(event);
        }
        if (isLocalUrl(toLink) && prefetch) {
            typeof prefetch === "number"
                ? setDoDelayedPrefetch(true)
                : setDoPrefetch(true);
        }
    };

    const onMouseLeaveHandler = (event: MouseEvent): void => {
        if (onMouseLeave) {
            onMouseLeave(event);
        }
        setDoDelayedPrefetch(false);
    };

    const onTouchStartHandler = (event: TouchEvent): void => {
        if (onTouchStart) {
            onTouchStart(event);
        }
    };

    const onTouchEndHandler = (event: TouchEvent): void => {
        if (onTouchEnd) {
            onTouchEnd(event);
        }
    };

    if (
        isLocalUrl(toLink) &&
        !isFile(toLink) &&
        !isOnPageAnchor &&
        typeof window !== "undefined" &&
        window?.location
    ) {
        const currentUrl = new URL(window.location.href);
        const toUrl = toLink.startsWith("/")
            ? new URL(toLink, window.location.origin)
            : new URL(toLink);

        toLink = transferSearchParams(
            currentUrl,
            toUrl,
            searchParamsToKeep,
        ).toString();
    }

    const page =
        toLink.startsWith("http") || toLink.startsWith("//")
            ? toLink.replace(/^.*\/\/[^/]+/, "")
            : toLink;

    const isLocal = !isFile(toLink) && isLocalUrl(toLink) && !fullfetch;

    const childContent = <>{children}</>;

    if (isLocal && isNavigation)
        return (
            <>
                {doPrefetch && (
                    <Helmet>
                        <link rel="prefetch" href={prefetchURL(page)} />
                    </Helmet>
                )}
                <NavigationLink
                    className={classes}
                    to={page}
                    onClick={onClickHandler}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                    onTouchStart={onTouchStartHandler}
                    onTouchEnd={onTouchEndHandler}
                    tabIndex={tabIndex}
                    aria-label={ariaLabel}
                    aria-current={ariaCurrent}
                    $hoverSpan={hoverSpan}
                    title={title}
                    style={style}
                    smooth
                    id={id}
                    {...addGtmClickTypeAttributes(gtmClickType)}
                >
                    {childContent}
                </NavigationLink>
            </>
        );
    else if (isLocal)
        return (
            <>
                {doPrefetch && (
                    <Helmet>
                        <link rel="prefetch" href={prefetchURL(page)} />
                    </Helmet>
                )}
                <ReduxLink
                    className={classes}
                    to={page}
                    onClick={onClickHandler}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                    onTouchStart={onTouchStartHandler}
                    onTouchEnd={onTouchEndHandler}
                    tabIndex={tabIndex}
                    aria-label={ariaLabel}
                    aria-current={ariaCurrent}
                    $hoverSpan={hoverSpan}
                    title={title}
                    style={style}
                    smooth
                    id={id}
                    {...addGtmClickTypeAttributes(gtmClickType)}
                >
                    {childContent}
                </ReduxLink>
            </>
        );
    else
        return (
            <A
                href={toLink}
                className={classes}
                target={target || "_self"}
                onClick={onClickHandler}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                onTouchStart={onTouchStartHandler}
                onTouchEnd={onTouchEndHandler}
                aria-label={ariaLabel}
                aria-current={ariaCurrent}
                $hoverSpan={hoverSpan}
                title={linkTitle}
                style={style}
                id={id}
            >
                {childContent}
            </A>
        );
};

export const Obfuscated = ({
    ariaLabel,
    ariaCurrent,
    title,
    id,
    email,
    tel,
    obfuscateChildren = true,
    children,
    hoverSpan = false,
    className,
    style = {},
    tabIndex = 0,
    gtmClickType,
}: OfuscatedLinkProps): ReactElement => {
    style = { direction: "ltr", ...style };
    const classes = classNames(className, { hoverspan: hoverSpan });
    const header = useHeader();
    const startPageUrl = header.startPageUrl;

    // Fix strange Epi mailto links
    email =
        email &&
        email.replace(/:25$/, "").replace("mailto:", "").replace("/", "");

    return (
        <ObfuscatedLink
            className={classes}
            email={email}
            tel={tel}
            aria-label={ariaLabel}
            aria-current={ariaCurrent}
            title={title || ""}
            style={style}
            tabIndex={tabIndex}
            $hoverSpan={hoverSpan}
            obfuscateChildren={obfuscateChildren}
            linkText={startPageUrl || "/"}
            id={id}
            {...addGtmClickTypeAttributes(gtmClickType)}
        >
            {children}
        </ObfuscatedLink>
    );
};

export default React.memo(Link, isEqual);
