import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import Image from "components/Image/Image";
import Section from "components/Section";
import useIsInEditMode from "hooks/useIsInEditMode";
import { BREAKPOINT_NUMBER } from "style/breakpoints";
import generateSrcSet from "style/generateSrcSet";
import { addEditAttributes } from "utils/episerver";

import {
    Container,
    Heading,
    Introduction,
    Description,
    StyledTextBlock,
    FormWrapper,
    StyledForm,
    LeftCol,
    RightCol,
} from "./FormWithImage.styled";
import FormWithImageProps from "./FormWithImageProps";

const FormWithImage = ({
    heading,
    introduction,
    mainBody,
    identifier,
    anchorName,
    theme = "lightgray",
    displayOption = "Left",
    actionTheme,
    contentTheme,
    image,
    form,
    formFieldSize = "small",
}: FormWithImageProps): ReactElement => {
    const isInEditMode = useIsInEditMode();

    if (image && image.url) {
        image.srcSet = generateSrcSet({
            image: image.url,
            focalPoint: image.focalPoint,
            content: [
                {
                    width: 620,
                    breakpoint: BREAKPOINT_NUMBER.XL,
                },
                {
                    width: 519,
                    breakpoint: BREAKPOINT_NUMBER.L,
                },
                {
                    width: 363,
                    breakpoint: BREAKPOINT_NUMBER.M,
                },
                {
                    width: 768,
                },
            ],
            maxWidth: image.uploadedWidth,
            maxHeight: image.uploadedHeight,
        });
    }

    const FormContent: React.FC = React.memo(() => {
        return (
            <>
                {(isInEditMode || heading || form?.heading) && (
                    <Heading
                        {...addEditAttributes("Heading")}
                        dangerouslySetInnerHTML={{
                            __html: heading || form?.heading || "",
                        }}
                    />
                )}
                {(isInEditMode || introduction || form?.introduction) && (
                    <Introduction
                        {...addEditAttributes("Introduction")}
                        dangerouslySetInnerHTML={{
                            __html: introduction || form?.introduction || "",
                        }}
                    />
                )}
                {(isInEditMode || mainBody) && (
                    <Description {...addEditAttributes("MainBody")}>
                        {mainBody && (
                            <StyledTextBlock
                                content={mainBody}
                                areaName="mainBody"
                                editorialGrid={true}
                            />
                        )}
                    </Description>
                )}
                {form?.formElements && (
                    <FormWrapper>
                        <StyledForm
                            actionTheme={actionTheme}
                            items={form.formElements}
                            areaName="Form"
                            size={formFieldSize}
                            identifier={`form-with-image-${identifier}`}
                        />
                    </FormWrapper>
                )}
            </>
        );
    });
    FormContent.displayName = "FormContent";

    return (
        <ThemeProvider
            theme={{
                displayOption: displayOption,
                blockInBlock: true,
                actionTheme: actionTheme,
                contentTheme: contentTheme,
            }}
        >
            <Section theme={theme} alignItems="center">
                <Container key={identifier} id={anchorName}>
                    <LeftCol>
                        {displayOption === "Left" ? (
                            <FormContent />
                        ) : displayOption === "Right" ? (
                            <Image {...image} />
                        ) : (
                            <></>
                        )}
                    </LeftCol>
                    <RightCol>
                        {displayOption === "Left" ? (
                            <Image {...image} />
                        ) : displayOption === "Right" ? (
                            <FormContent />
                        ) : (
                            <></>
                        )}
                    </RightCol>
                </Container>
            </Section>
        </ThemeProvider>
    );
};

export default React.memo(FormWithImage);
